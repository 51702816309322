import Intro from "../../../../../gatsby-theme-teranova/src/components/intro";
import About from "../../../../../gatsby-theme-teranova/src/components/about";
import Services from "../../../../../gatsby-theme-teranova/src/components/services";
import Contact from "../../../../../gatsby-theme-teranova/src/components/contact";
import Team from "../../../../../gatsby-theme-teranova/src/components/team";
import React from 'react';
export default {
  Intro,
  About,
  Services,
  Contact,
  Team,
  React
};